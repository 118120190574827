export const TARGET_TYPES = ['quatrix', 'remote_site', 'agent', 'agent_pool'];
export const REMOTE_TYPES = ['remote_site', 'agent', 'agent_pool'];

export const MOMENT_FORMATS = {
  DAY: 'DD',
  MONTH: 'MMM',
  YEAR: 'YYYY',
  DATE: 'DD MMM YYYY',
  TIME: 'HH:mm',
  DATE_TIME: 'DD MMM YYYY HH:mm',
  DATE_FULL_TIME: 'DD MMM YYYY HH:mm:ss',
  TRACKING_DATE_TIME: 'DD MMM YYYY, HH:mm',
  CARD_EXPIRY_DATE: 'MM/YY',
};

export const QUATRIX_PLANS = {
  QXP: 'Quatrix Personal',
  QC2: 'Quatrix China 2',
  QC5: 'Quatrix China 5',
  QC10: 'Quatrix China 10',
  QC20: 'Quatrix China 20',
  QC50: 'Quatrix China 50',
  QC100: 'Quatrix China 100',
  QC500: 'Quatrix China 500',
  QC_5: 'Quatrix China 5',
  QBP_50: 'Quatrix Business Professional 50',
  QBB_G: 'Quatrix G-cloud Basic',
  SFTP: 'SFTP',
  QBS: 'Quatrix Business Starter',
  QC_50: 'Quatrix China 50',
  QC_20: 'Quatrix China 20',
  QBE_G: 'Quatrix G-cloud Enterprise',
  QB: 'Quatrix Business',
  QBB: 'Quatrix Business Basic',
  QC_CUSTOM: 'Quatrix China Custom',
  QBS_G: 'Quatrix G-cloud Starter',
  QBP_200: 'Quatrix Business Professional 200',
  QCT: 'Quatrix Trial',
  QC_10: 'Quatrix China 10',
  QXB: 'Quatrix Business',
  QC_100: 'Quayrix China 100',
  QBE: 'Quatrix Business Enterprise',
  QBP_G_100: 'Quatrix G-cloud Professional 100',
  ESM: 'Enterprise Security Module',
  QBP: 'Quatrix Business Professional',
  QB_CUSTOM: 'Quatrix Business Custom',
  ESM_CUSTOM: 'Enterprise Security Module',
  QBP_G_200: 'Quatrix G-cloud Professional 200',
  QC_500: 'Quatrix China 500',
  QXBT: 'Quatrix Trial',
  ES_CUSTOM: 'Extra Storage',
  ES: 'Extra Storage',
  QBP_G: 'Quatrix G-cloud Professional',
  QBP_G_50: 'Quatrix G-cloud Professional 50',
  QBP_100: 'Quatrix Business Professional 100',
};

export const SUPPORT_EMAIL = 'servicedesk@quatrix.it';

export const CONTACT_PAGE = 'https://www.maytech.net/contact';

export const DEFAULT_LANG = 'en_GB';

export const AVAILABLE_LANGS = {
  en_GB: 'British English',
  zh_CN: 'Chinese',
  de_DE: 'German',
};

export const DAY = 24 * 60 * 60 * 1000;

// Internal FE codes
export const FORGOT_PASSWORD_CODE = 11;

//error codes
export const OBJECT_EXISTS_CODE = 30;
export const USER_EXISTS_CODE = 31;
export const CONTACT_EXISTS_CODE = 32;
export const PGP_DISABLED_CODE = 51;
export const INVALID_2FA_CODE = 83;

// Codes from BE
export const SETUP_2FA_CODE = 61;
export const EMAIL_CODE = 70;
export const LOGIN_SSO = 88;
export const TWOFA_CODE = 71;
export const PIN_ON_SHARE_CODE = 72;
export const PIN_CODE = 73;
export const CAPTCHA_CODE = 74;
export const PASSWORD_CODE = 75;
export const EMAIL_REGISTRATION_CODE = 76;

export const MFA_LOGIN_CODES = [SETUP_2FA_CODE, TWOFA_CODE, PIN_ON_SHARE_CODE, PIN_CODE, PASSWORD_CODE];

export const MFA_SAVE_STATE_CODES = [PASSWORD_CODE];

export const TAGS_TYPE = {
  file: 'file',
  automation: 'automation',
};

export const CSV_USER_IMPORT_FIELDS = {
  email: 'email',
  name: 'name',
  active: 'active',
  admin: 'admin',
  groups: 'group',
  pin: 'pin',
  enable_sftp: 'enable_sftp',
  unique_login: 'sftp_login',
};
