export const EMAIL_MAX_LENGTH = 128;
export const EMAIL_LOCALE_PART_MAX_LENGTH = 64;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 64;
export const PASSWORD_SCORED_MIN_LENGTH = 12;
export const PASSWORD_SCORED_MAX_LENGTH = 64;
export const PASSWORD_MIN_SCORE = 3;

export const USER_NAME_MAX_LENGTH = 128;

export const FILE_NAME_MAX_LENGTH = 255;
export const EMAIL_FOOTER_MAX_LENGTH = 10000;
//Emailsignature on profile tab, it will be move to 10000 soon
export const PROFILE_EMAIL_FOOTER_MAX_LENGTH = 500;

export const UPGRADE_MAX_USERS = 49;

export const MAX_SFTP_LOGIN_LENGTH = 64;
export const NAME_MAX_LENGTH = 128;

export const SUBJECT_MAX_LENGTH = 500;
