import store from '@/store/index.js';
import { defineRule, validate } from 'vee-validate';
import moment from 'moment';
import isGlob from 'is-glob';
import { convertUnits, stringEqualsIgnoreCase } from '@/elements/utils.js';
import { toRaw } from 'vue';

import gettext from '@/utils/translationInjector.js';
const { $gettext, interpolate, $ngettext } = gettext;

import cronstrue from 'cronstrue';

//We can import all already defined rules from vee-validate once
//import * as defaultRules from 'vee-validate/dist/rules';
//But i think we need engine of validation even more then rules, so we can import only required fields
import { digits, between } from '@vee-validate/rules';
import {
  EMAIL_REG_EXP,
  FILE_NAME_HAS_DOTS_REG_EXP,
  FILE_NAME_HAS_SLASHES_REG_EXP,
  EMAIL_FOOTER_REGEX,
  IP_REGEX,
  URL_REGEX,
  CARD_NUMBER_REGEX,
  CARD_HOLDER_REGEX,
  ZIP_REGEX,
  SFTP_REGEX,
  HOST_REGEX,
  AZURE_BUCKET_NAME_REGEX,
  B2_BUCKET_NAME_REGEX,
  S3_BUCKET_NAME_REGEX,
  GCS_NO_DOTS_BUCKET_NAME_REGEX,
  GCS_WITH_DOTS_BUCKET_NAME_REGEX,
  CRON_REGEXES,
} from './regExps';
import {
  EMAIL_MAX_LENGTH,
  EMAIL_LOCALE_PART_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  PASSWORD_SCORED_MIN_LENGTH,
  PASSWORD_SCORED_MAX_LENGTH,
  PASSWORD_MIN_SCORE,
  USER_NAME_MAX_LENGTH,
  FILE_NAME_MAX_LENGTH,
  EMAIL_FOOTER_MAX_LENGTH,
  PROFILE_EMAIL_FOOTER_MAX_LENGTH,
  UPGRADE_MAX_USERS,
  MAX_SFTP_LOGIN_LENGTH,
  SUBJECT_MAX_LENGTH,
} from './cons.js';
import { filesize } from '@/elements/utils.js';
import checkSSHKey from './sshKeyValidation';
import { isSystemFolder, isProjectsFolder, isUserHomesFolder } from '@/utils/files.js';

defineRule('between', between);

defineRule('required', (value) => {
  if (!value || value?.length === 0) {
    return $gettext('Required field');
  }
  return true;
});

defineRule('max', (value, [limit]) => {
  if (!value || value?.length === 0) {
    return true;
  }
  if (value?.length > limit) {
    return interpolate($gettext('Maximum %{ max } characters allowed'), { max: limit });
  }
  return true;
});

defineRule('min', (value, [limit]) => {
  if (!value || value?.length === 0) {
    return true;
  }
  if (value?.length < limit) {
    return interpolate(
      $ngettext('Minimum - %{ limit } character required', 'Minimum - %{ limit } characters required', limit),
      {
        limit,
      }
    );
  }
  return true;
});

defineRule('email', async (value) => {
  if (!value) {
    return;
  }
  const { valid, errors } = await validate(value);
  if (!valid) {
    return errors[0];
  }
  if (value?.length > EMAIL_MAX_LENGTH) {
    return interpolate($gettext('Maxlength - %{length} symbols'), { length: EMAIL_MAX_LENGTH });
  }
  if (EMAIL_REG_EXP.test(value)) {
    const localPart = value.indexOf('@');
    if (localPart > EMAIL_LOCALE_PART_MAX_LENGTH) {
      return interpolate(
        $ngettext(
          'Local part should be less than %{ max } character',
          'Local part should be less than %{ max } characters',
          EMAIL_LOCALE_PART_MAX_LENGTH
        ),
        {
          max: EMAIL_LOCALE_PART_MAX_LENGTH,
        }
      );
    }
  } else {
    return $gettext('Email is incorrect');
  }
  return true;
});

defineRule('password', async (value) => {
  const { valid, errors } = await validate(value);
  if (!valid) {
    return errors[0];
  }
  if (value?.length < PASSWORD_MIN_LENGTH) {
    return interpolate($gettext('Minlength - %{length} symbols'), { length: PASSWORD_MIN_LENGTH });
  }
  if (value?.length > PASSWORD_MAX_LENGTH) {
    return interpolate($gettext('Maxlength - %{length} symbols'), { length: PASSWORD_MAX_LENGTH });
  }
  return true;
});

defineRule('subject', (value) => {
  if (value?.length > SUBJECT_MAX_LENGTH) {
    return interpolate($gettext('Maxlength - %{length} symbols'), { length: SUBJECT_MAX_LENGTH });
  }
  if (value.indexOf('@') !== -1) {
    return $gettext('The subject with symbol "@" is forbidden');
  }
  return true;
});

defineRule('file-name', (value) => {
  if (value?.length > FILE_NAME_MAX_LENGTH) {
    return interpolate($gettext('Filename maxlength - %{length} symbols'), { length: FILE_NAME_MAX_LENGTH });
  }
  if (FILE_NAME_HAS_DOTS_REG_EXP.test(value)) {
    return $gettext("'.' or '..' are forbidden");
  }
  if (FILE_NAME_HAS_SLASHES_REG_EXP.test(value)) {
    return $gettext('Slash and backslash are forbidden');
  }
  return true;
});

defineRule('gcs-bucket-name', (value) => {
  if (!value) {
    return true;
  }
  if (value.length < 3) {
    return interpolate($gettext('Minlength - %{length} symbols'), { length: 3 });
  }
  if (value.includes('.')) {
    if (value.length > 222) {
      return interpolate($gettext('Maxlength - %{length} symbols'), { length: 222 });
    }

    if (!GCS_WITH_DOTS_BUCKET_NAME_REGEX.test(value) || IP_REGEX.test(value)) {
      return $gettext('Bucket name is incorrect');
    }
  } else {
    if (value.length > 63) {
      return interpolate($gettext('Maxlength - %{length} symbols'), { length: 63 });
    }

    if (!GCS_NO_DOTS_BUCKET_NAME_REGEX.test(value)) {
      return $gettext('Bucket name is incorrect');
    }
  }
  return true;
});

defineRule('isJSON', (value) => {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return $gettext('Incorrect JSON format');
  }
});

defineRule('max-users', (value) => {
  if (value > UPGRADE_MAX_USERS) {
    return interpolate(
      $gettext('Please contact %{ openTag }Quatrix Customer Care%{ closeTag } for %{ max }+ licences'),
      {
        openTag: '<a href="mailto:' + SUPPORT_EMAIL + '">',
        closeTag: '</a>',
        max: UPGRADE_MAX_USERS,
      },
      true
    );
  }
  return true;
});

defineRule(
  'min-max-value',
  (minMaxValue, [minValue, maxValue, userLimitValue, generalLimitValue = UPGRADE_MAX_USERS]) => {
    const value = parseInt(minMaxValue, 10);
    const min = parseInt(minValue, 10);
    const max = maxValue ? parseInt(maxValue, 10) : Infinity;
    const userLimit = parseInt(userLimitValue, 10);
    if (Number.isNaN(value)) {
      return $gettext('You can use only numbers');
    }
    if (value < min) {
      return interpolate($gettext('Minimum user licences - %{ min }'), { min }, true);
    }
    if (value > max && value < generalLimitValue) {
      return interpolate($gettext('Maximum user licences - %{ max }'), { max }, true);
    }
    if (value < userLimit) {
      return $gettext('Delete some users to decrease user licences');
    }
    return true;
  }
);

defineRule('min_value', (value, [minValue]) => {
  if (!value || value?.length === 0) {
    return true;
  }
  if (Number(value) < minValue) {
    return interpolate($gettext('Should be %{ minValue } or more'), { minValue });
  }
  return true;
});

defineRule('max_value', (value, [maxValue]) => {
  if (!value || value?.length === 0) {
    return true;
  }
  if (value === 'notify') {
    // automation delete users
    return $gettext('Should be equal or less then Inactive days');
  }
  if (Number(value) > maxValue) {
    return interpolate($gettext('Should be %{ maxValue } or less'), { maxValue });
  }
  return true;
});

defineRule('is_not', (value, [compareValue]) => {
  if (value === compareValue) {
    return $gettext("Can't be equal");
  }
  return true;
});

defineRule('password-with-score', async (value) => {
  const checkScore = async (value) => {
    const zxcvbn = await import('zxcvbn').then(({ default: module }) => module);
    const score = zxcvbn(value).score;
    return score >= PASSWORD_MIN_SCORE;
  };
  if (!value) {
    return true;
  }
  if (value?.length > PASSWORD_SCORED_MAX_LENGTH) {
    return interpolate($gettext('Maxlength - %{length} symbols'), { length: PASSWORD_SCORED_MAX_LENGTH });
  }
  if (value?.length < PASSWORD_SCORED_MIN_LENGTH) {
    return interpolate($gettext('Minlength - %{length} symbols'), { length: PASSWORD_SCORED_MIN_LENGTH });
  }
  if ((await checkScore(value)) === false) {
    return $gettext('Your password is too weak');
  }
  return true;
});

defineRule('is-equal', (value, [isEqual]) => {
  if (isEqual !== true) {
    return $gettext('Passwords should be equal');
  }
  return true;
});

defineRule('cant-be-equal', (value, [isEqual]) => {
  if (isEqual === true) {
    return $gettext("Can't be equal");
  }
  return true;
});

defineRule('digits', (value, [length]) => {
  if (!digits(value, { length })) {
    return interpolate($gettext('Enter %{ length }-digit value'), { length });
  }
  return true;
});

defineRule('profile-name', async (value) => {
  if (value?.length > USER_NAME_MAX_LENGTH) {
    return interpolate($gettext('Profile name maxlength is %{ length }'), { length: USER_NAME_MAX_LENGTH });
  }
  if (value.indexOf('@') !== -1) {
    return $gettext('Profile name with symbol "@" is forbidden');
  }
  if (value.indexOf('://') !== -1) {
    return $gettext('Profile name cannot contain special character sequence "://"');
  }
  return true;
});

defineRule('quota', (value, [quotaUnit]) => {
  if (!value) {
    return true;
  }
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  const size = parseInt(value, 10);
  let maxQuota = store.state.profile.storageLimit;
  let biteSize;

  const fromIndex = units.indexOf(quotaUnit);
  if (~fromIndex) {
    biteSize = size * Math.pow(1000, fromIndex);
  }
  if (quotaUnit === 'U' || !Boolean(quotaUnit)) {
    return $gettext('Incorrect units');
  }
  if (isNaN(biteSize) || biteSize < 0) {
    return $gettext('Incorrect quota value');
  }
  if (biteSize > maxQuota) {
    return interpolate($gettext('The value cannot be greater than %{ max }'), {
      max: filesize(maxQuota),
    });
  }
  return true;
});

defineRule(
  'reserved_storage',
  (value, [reservedStorageUnit, quota, quotaUnit, initReservedStorage, initReservedStorageUnit, usersSelected]) => {
    if (!value) {
      return true;
    }
    const size = convertUnits(value, reservedStorageUnit, 'B');
    const initialSize = convertUnits(initReservedStorage, initReservedStorageUnit, 'B');
    const quotaSize = convertUnits(quota, quotaUnit, 'B');
    const reservationLimit = store.state.profile.storageLimit - store.state.profile.totalReservations;
    const currentLimit =
      +usersSelected > 1 ? Math.floor(reservationLimit / usersSelected) : initialSize + reservationLimit;
    const maxReservationValue = quotaSize <= currentLimit && quotaUnit !== 'U' ? quotaSize : currentLimit;

    if (isNaN(size) || size < 0) {
      return $gettext('Incorrect value');
    }
    if (size > maxReservationValue) {
      return interpolate($gettext('The value cannot be greater than %{ max }'), {
        max: filesize(maxReservationValue),
      });
    }
    return true;
  }
);

defineRule('ssh-key', (value) => {
  if (!value) {
    return true;
  }
  if (checkSSHKey(value) === false) {
    return $gettext('Incorrect SSH key');
  }
  return true;
});

defineRule('email-footer', (value) => {
  if (value?.length > EMAIL_FOOTER_MAX_LENGTH) {
    return interpolate($gettext('Email footer should be less than %{ max } characters long'), {
      max: EMAIL_FOOTER_MAX_LENGTH,
    });
  }
  if (EMAIL_FOOTER_REGEX.test(value)) {
    return $gettext('Backslash is forbidden');
  }
  return true;
});

defineRule('email-profile-footer', (value) => {
  if (value?.length > PROFILE_EMAIL_FOOTER_MAX_LENGTH) {
    return interpolate($gettext('Email footer should be less than %{ max } characters long'), {
      max: PROFILE_EMAIL_FOOTER_MAX_LENGTH,
    });
  }
  if (EMAIL_FOOTER_REGEX.test(value)) {
    return $gettext('Backslash is forbidden');
  }
  return true;
});

defineRule('ip', (value) => {
  if (!value) {
    return true;
  }
  if (!IP_REGEX.test(value)) {
    return $gettext('IP is incorrect');
  }
  return true;
});

defineRule('url', (value) => {
  if (!value) {
    return true;
  }
  if (!URL_REGEX.test(value)) {
    return $gettext('URL is incorrect');
  }
  return true;
});

defineRule('card-number', (value, [type]) => {
  if (type === 'american-express') {
    return $gettext("American Express can't be used");
  }
  const cardNumber = value.replace(/\s/g, '');
  if (!CARD_NUMBER_REGEX.test(cardNumber)) {
    return $gettext('Credit card number is incorrect');
  }
  return true;
});

defineRule('card-holder-name', (value) => {
  if (!CARD_HOLDER_REGEX.test(value)) {
    return $gettext('Incorrect name');
  }
  return true;
});

defineRule('card-expiration', (value) => {
  const expirationDate = value.split('/');
  const month = parseInt(expirationDate[0], 10);
  const year = parseInt(expirationDate[1], 10);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = parseInt(currentDate.getFullYear().toString().slice(-2), 10);
  if (!month || month > 12 || (month < currentMonth && year === currentYear)) {
    return $gettext('Expiry date month is incorrect');
  }
  if (!year || year < currentYear) {
    return $gettext('Expiry date year is incorrect');
  }
  return true;
});

defineRule('zip', (value) => {
  if (!value) {
    return true;
  }
  if (!ZIP_REGEX.test(value)) {
    return $gettext('Zip is incorrect');
  }
  return true;
});

defineRule('max-users', (value) => {
  if (value > UPGRADE_MAX_USERS) {
    return $gettext('Maximum user licences exceeded');
  }
  return true;
});

defineRule('sftp-login', async (value) => {
  if (!value) {
    return true;
  }
  const { valid, errors } = await validate(value, { max: MAX_SFTP_LOGIN_LENGTH });
  if (!valid) {
    return errors[0];
  }
  if (!SFTP_REGEX.test(value)) {
    return $gettext('Custom login is incorrect');
  }
  return true;
});

defineRule('release-date', (releaseDate, [expiryDate, isNew]) => {
  const relDate = releaseDate && moment(releaseDate).startOf('minute');
  const expDate = expiryDate && moment(expiryDate).startOf('minute');
  const currentDate = moment(new Date()).startOf('minute');

  if (!relDate) {
    return true;
  }
  if (expDate && expDate <= relDate) {
    return $gettext('The release date should be earlier than the expiry date');
  }
  if (isNew && currentDate >= relDate) {
    return $gettext('The release time should be later than now');
  }
  return true;
});

defineRule('is-glob', (value) => {
  if (!isGlob(value)) {
    return $gettext('Not a valid glob');
  }
  return true;
});

// Helper for defining validators that check for a given permission.
function makePermissionValidator(fileId, file, permission, errMessage) {
  // Exclude system folders from the check because their permissions are somewhat special.
  // See also the validator 'not-protected-system-folder'.
  if (isSystemFolder(file)) {
    return true;
  }

  // FIXME: Never check for the 'delete' or 'rename' on project folders.  This
  // is a kludge to mend the fact that ATM backend returns the permissions for
  // a project folder ITSELF and you can never see 'delete' or 'rename' there,
  // even if you actually can delete/rename files INSIDE OF that project
  // folder.
  if (file.isProjectFolder && ['delete', 'rename'].includes(permission)) {
    return true;
  }

  if (!store.getters['files/checkOperations']({ permissions: file.permissions }, permission)) {
    return errMessage;
  }
  return true;
}

defineRule('rename-permission', (fileId, [file]) =>
  makePermissionValidator(fileId, file, 'rename', $gettext("You don't have permission to rename in the folder"))
);

defineRule('upload-permission', (fileId, [file]) =>
  makePermissionValidator(fileId, file, 'upload', $gettext("You don't have permission to upload into the folder"))
);

defineRule('download-permission', (fileId, [file]) =>
  makePermissionValidator(fileId, file, 'download', $gettext("You don't have permission to download from the folder"))
);

defineRule('delete-permission', (fileId, [file]) =>
  makePermissionValidator(fileId, file, 'delete', $gettext("You don't have permission to delete in the folder"))
);

defineRule('mkdir-permission', (fileId, [file]) =>
  makePermissionValidator(
    fileId,
    file,
    'mkdir',
    $gettext("You don't have permission to create folders inside the folder")
  )
);

defineRule('not-protected-system-folder', (fileId, [file]) => {
  if (isProjectsFolder(file) || isUserHomesFolder(file)) {
    return $gettext('This folder cannot be source or destination for automations');
  }
  return true;
});

defineRule('external-same-remote-path', (value, [isSameRemote, source, destination]) => {
  if (!isSameRemote) {
    return true;
  }
  const removeLastSlash = (path) => (['/', '\\'].includes(path?.slice(-1)) ? path.slice(0, -1) : path);
  const sourcePath = source ? removeLastSlash(source) : source;
  const destinationPath = destination ? removeLastSlash(destination) : destination;
  if (sourcePath === destinationPath) {
    return $gettext('The source directory should not be the same as the destination directory');
  }
  return true;
});

defineRule('external-target-types', (value, [sourceType, destinationType, isSameRemote, isAllowed]) => {
  if (isSameRemote && !isAllowed) {
    return $gettext('This remote site can be used only as a source, or as a destination');
  }
  if (!isSameRemote && sourceType !== 'quatrix' && destinationType !== 'quatrix') {
    return $gettext('Selected remotes are not the same');
  }
  return true;
});

defineRule('external-reserve-path', (value, [source, reserve]) => {
  const { path: reservePath, id: reserveId } = reserve;
  const { path, id } = source;
  const isIds = !path || (id !== null && reserveId !== null);
  const samePath = !isIds && path === reservePath;
  const sameIds = isIds ? id === reserveId : false;
  if (sameIds || samePath) {
    return $gettext('The reserve copy directory should not be the same as the source directory');
  }
  return true;
});

defineRule('external-backup-path', (value, [destination, source, backup]) => {
  const { path: destinationPath, id: destinationId } = destination;
  const { path: backupPath, id: backupId } = backup;
  const { path: sourcePath, id: sourceId } = source;
  const checkPath = (type, id, path) => {
    let isIds = !path || (id !== null && backupId !== null);
    if (type === 'source' && !path && !id) {
      isIds = false; // if source directory is not selected yet
    }
    const samePath = !isIds && path === backupPath;
    const sameIds = isIds ? id === backupId : false;
    if (sameIds || samePath) {
      return interpolate($gettext('The backup directory should not be the same as the %{ type } directory'), { type });
    } else if (type !== 'source') {
      return checkPath('source', sourceId, sourcePath);
    }
    return true;
  };
  return checkPath('destination', destinationId, destinationPath);
});

defineRule('unique-name', (value, list) => {
  if (list.includes(value)) {
    return $gettext('The name is already in use');
  }
  return true;
});

defineRule('unique-name', (value, list) => {
  if (list.includes(value)) {
    return $gettext('The name is already in use');
  }
  return true;
});

defineRule('unique-name-ignore-case', (value, list) => {
  if (list.some((existingName) => stringEqualsIgnoreCase(existingName, value))) {
    return $gettext('The name is already in use');
  }
  return true;
});

defineRule('min-age', async (minValue, [maxValue, minUnit, maxUnit]) => {
  if (!maxValue) {
    return true;
  }

  if (minUnit === maxUnit) {
    const { valid: isValid, errors: isErrors } = await validate(minValue, {
      is_not: maxValue,
    });
    if (!isValid) {
      return isErrors[0];
    }

    const { valid: maxValid, errors: maxErrors } = await validate(minValue, {
      max_value: maxValue,
    });
    if (!maxValid) {
      return maxErrors[0];
    }
  } else {
    const minAge = moment.duration(minValue, minUnit).asMilliseconds();
    const maxAge = moment.duration(maxValue, maxUnit).asMilliseconds();
    const minMaxDiff = maxAge - minAge;
    if (minMaxDiff < 0) {
      return $gettext('Should be less than "Maximum"');
    }
    if (minMaxDiff === 0) {
      return $gettext("Can't be equal");
    }
  }
  return true;
});

defineRule('min-size', async (minValue, [maxValue, minUnit, maxUnit]) => {
  if (!maxValue) {
    return true;
  }
  if (minUnit === maxUnit) {
    const { valid: isValid, errors: isErrors } = await validate(minValue, {
      is_not: maxValue,
    });
    if (!isValid) {
      return isErrors[0];
    }
    const { valid: maxValid, errors: maxErrors } = await validate(minValue, {
      max_value: maxValue,
    });
    if (!maxValid) {
      return maxErrors[0];
    }
  } else {
    const units = ['b', 'k', 'M', 'G'];
    const minIndex = units.indexOf(minUnit);
    const maxIndex = units.indexOf(maxUnit);
    const minSizeInBytes = minValue * Math.pow(1000, minIndex);
    const maxSizeInBytes = maxValue * Math.pow(1000, maxIndex);
    if (minSizeInBytes === maxSizeInBytes) {
      return $gettext("Can't be equal");
    }
    if (maxSizeInBytes < minSizeInBytes) {
      return $gettext('Should be less than "Maximum"');
    }
  }
  return true;
});

defineRule('host', (value) => {
  if (!HOST_REGEX.test(value)) {
    return $gettext('Hostname is incorrect');
  }
  return true;
});

defineRule('azure-bucket-name', async (value) => {
  if (!value) {
    return true;
  }
  const { valid, errors } = await validate(value, {
    min: 3,
    max: 63,
  });
  if (!valid) {
    return errors[0];
  }
  if (!AZURE_BUCKET_NAME_REGEX.test(value)) {
    return $gettext('Container name is incorrect');
  }
  return true;
});

defineRule('azure-share-name', async (value) => {
  if (!value) {
    return true;
  }
  const { valid, errors } = await validate(value, {
    min: 3,
    max: 63,
  });
  if (!valid) {
    return errors[0];
  }
  if (!AZURE_BUCKET_NAME_REGEX.test(value)) {
    return $gettext('Share name is incorrect');
  }
  return true;
});

defineRule('b2-bucket-name', async (value) => {
  const { valid, errors } = await validate(value, {
    min: 6,
    max: 50,
  });
  if (!valid) {
    return errors[0];
  }
  if (!B2_BUCKET_NAME_REGEX.test(value)) {
    return $gettext('Bucket name is incorrect');
  }
  return true;
});

defineRule('s3-bucket-name', async (value) => {
  if (!value) {
    return true;
  }
  const { valid, errors } = await validate(value, {
    min: 3,
    max: 63,
  });
  if (!valid) {
    return errors[0];
  }
  if (value.endsWith('s3alias') || !S3_BUCKET_NAME_REGEX.test(value) || IP_REGEX.test(value)) {
    return $gettext('Bucket name is incorrect');
  }
  return true;
});

defineRule('autocomplete_value', async (val, [autocomplete_list]) => {
  const value = toRaw(val);
  if (!value || value.length === 0) {
    return true;
  }
  let match = '';
  autocomplete_list.forEach((text) => {
    if (text === value) {
      match = value;
      return;
    }
  });
  if (match.length === 0) {
    return $gettext('You can add items only from auto complete list');
  }
  return true;
});
defineRule('cron', (value, lang) => {
  const wrongMessage = $gettext('Invalid cron expression');
  const cronParts = value.split(' ');
  if (cronParts.length !== 5) {
    return wrongMessage;
  }

  for (const part of cronParts) {
    if (!CRON_REGEXES.numeric.test(part) && !CRON_REGEXES.anyValue.test(part)) {
      if (part.includes(',')) {
        // Check if part is a value list separated by commas
        const values = part.split(',');
        for (const value of values) {
          if (
            !CRON_REGEXES.numeric.test(value) &&
            !CRON_REGEXES.anyValue.test(value) &&
            !CRON_REGEXES.range.test(value)
          ) {
            return wrongMessage;
          }
        }
      } else if (part.includes('/')) {
        // Check if part is a step value
        const match = part.match(CRON_REGEXES.step);
        if (!match || match.length !== 4 || match[3] < 1) {
          return wrongMessage;
        }
      } else if (part.includes('-')) {
        // Check if part is a range of values separated by a hyphen
        const match = part.match(CRON_REGEXES.range);
        if (!match || match.length !== 3) {
          return wrongMessage;
        }
        const [_, start, end] = match.map(Number);
        if (start >= end || isNaN(start) || isNaN(end)) {
          return wrongMessage;
        }
      }
    }
  }

  try {
    const explanation = cronstrue.toString(value, { use24HourTimeFormat: true, locale: lang });
    return (explanation?.length > 0 && !CRON_REGEXES.undefinedReg.test(explanation)) || wrongMessage;
  } catch (error) {
    return wrongMessage;
  }
});
