import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { MOMENT_FORMATS } from '@/utils/cons.js';
import { getAutomationList, getAutomationMetadata, createAutomation, updateAutomation, deleteAutomation } from '@/api';

const DAY_IN_MINUTES = 1440; // 24*60

const ON_CONFLICT_DEFAULT = 'overwrite_older';

const ACTIVITY_EXPORT_MODEL = {
  action: 'activity_export',
  created: null,
  file_id: '',
  file_path: '',
  email: [],
  options: {
    ts: '',
    period: '1 month',
  },
  isActive: true,
  // Checked statuses in table
  isDisabledState: false,
  isCheckedState: false,
  isActiveState: false,
  tags: [],
};

const AUTODELETE_MODEL = {
  action: 'autodelete',
  created: null,
  file_id: '',
  file_path: '',
  email: [],
  options: {
    expire: '',
    keep_folder_structure: true,
    skip_home_folders: true,
    skip_shared_folders: true,
    super_trash: false,
  },
  isActive: true,
  // Checked statuses in table
  isDisabledState: false,
  isCheckedState: false,
  isActiveState: false,
  tags: [],
};

const AUTODELETE_CONTACTS_MODEL = {
  action: 'contact_autodelete',
  email: [],
  name: '',
  options: {
    protected_owner_ids: [],
    protected_group_ids: [],
    last_usage_threshold: 0,
  },
  protectedOwners: [],
  protectSiteContact: false, // internal
  inactiveDays: 30,
  isActive: true,
  // Checked statuses in table
  isDisabledState: false,
  isCheckedState: false,
  isActiveState: false,
  tags: [],
};

const AUTODELETE_USERS_MODEL = {
  action: 'user_autodelete',
  email: [],
  name: '',
  options: {
    group_ids: [], // required
    protected_role_ids: [],
    last_login_threshold: 0, // in minutes, required
    remove_expired: false,
    notify_users: false,
    target_admin_id: '', // required
    notify_before: 0, // in minutes, required
    isNotifyBefore: true, // internal
  },
  // internal
  groups: [],
  protectedUsers: [],
  inactiveDays: 30,
  notifyBefore: 1,
  isActive: true,
  // Checked statuses in table
  isDisabledState: false,
  isCheckedState: false,
  isActiveState: false,
  tags: [],
};

const FILES_OPERATION_MODEL = {
  action: 'file_move',
  email: [],
  name: '',
  options: {
    source_id: '',
    target_id: '',
    use_pattern: false,
    pattern: '',
    target_subfolders: false,
    rename: false,
    start_date: null,
    use_min_age: false,
    min_age_minutes: 0,
    on_conflict: ON_CONFLICT_DEFAULT,
    notify_options: {
      on_failure_only: true,
    },
  },
  isActive: true,
  // Checked statuses in table
  isDisabledState: false,
  isCheckedState: false,
  isActiveState: false,
  tags: [],
};

const EXTERNAL_AUTOMATION_MODEL = {
  action: 'external_file_operation',
  name: '',
  email: [],
  options: {
    operation: {
      type: 'move', // move || copy || sync || delete
      delete_empty_src_dirs: false,
      create_empty_src_dirs: true,
      delete_empty_dst_dirs: false,
    },
    filter: {
      use_max_age: false,
      use_min_age: false,
      use_min_size: false,
      use_max_size: false,
      min_age: null,
      max_age: null,
      min_size: null,
      max_size: null,
      patterns: [],
      use_patterns: false,
    },
    source: 'quatrix', // quatrix || remote_site || agent || agent_pool
    quatrix: {
      id: null, // folder_id
      path: null,
      //   options: {}, optional
      //   reserve_copy_id: null, // optional
      //   reserve_copy_path: '', // optional
    },
    remote_site: null,
    // Expected structure for remote site:
    // {
    //   id: null, // remote site id
    //   path: null,
    //   options: {}, optional
    //   reserve_copy_path: null, // optional
    // },
    agent: null,
    // Expected structure for remote agent:
    // {
    //   id: null, // remote agent id
    //   path: null,
    //   options: {}, optional
    //   reserve_copy_path: null, // optional
    // },
    agent_pool: null,
    // Expected structure for remote agent:
    // {
    //   id: null, // remote agent id
    //   path: null,
    //   options: {}, optional
    //   reserve_copy_path: null, // optional
    // },
    // same_remote: {
    //   destination_path: '',
    //   id: null,
    //   path: '',
    //   options: {}, optional
    //   reserve_copy_path: '',
    //   reserve_copy_id: null,
    // },
    start_date: null,
    notify_options: {
      on_failure_only: true,
    },
    config: {
      inplace: false,
      partial_suffix: '',
    },
  },
  isActive: true,
  // Checked statuses in table
  isDisabledState: false,
  isCheckedState: false,
  isActiveState: false,
  tags: [],
};

const getInitialState = () => ({
  automationsList: {
    autodelete: [],
    activity_export: [],
    user_autodelete: [],
    contact_autodelete: [],
    file_operation: [],
    external_file_operation: [],
    manual_stopping: [],
  },
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parse: () => (automation) => {
      const res = {};
      res.action = automation.action;
      res.id = automation.id;
      res.rule_num = automation.rule_num;
      res.created = automation.created;
      res.modified = automation.modified;
      res.createDate = moment(automation.created * 1000).format(MOMENT_FORMATS.DATE_TIME);
      res.file_id = automation.file_id;
      res.file_path = automation.file_path ? automation.file_path : '/';
      res.name = automation.name || '';
      res.email = automation.email || [];
      res.notes = automation.notes || '';
      res.options = automation.options || {};
      res.has_schedule = Boolean(automation.has_schedule);
      res.owner_name = automation.owner_name || '';
      if (automation.action === 'autodelete') {
        res.options.expire = res.options.expire || '';
      }
      if (automation.action === 'external_file_operation') {
        // For backward compatibility, older automations might not have the
        // 'notify_options' set at all.
        res.options.notify_options = res.options.notify_options ?? { on_failure_only: true };
      }
      if (automation.action === 'contact_autodelete') {
        res.inactiveDays = Math.round(res.options.last_usage_threshold / DAY_IN_MINUTES);
        res.options.protected_owner_ids = automation.options.protected_owner_ids || [];
        res.options.protected_group_ids = automation.options.protected_group_ids || [];
      }
      if (automation.action === 'user_autodelete') {
        res.inactiveDays = Math.round(res.options.last_login_threshold / DAY_IN_MINUTES);
        res.notifyBefore = res.options.notify_before ? Math.round(res.options.notify_before / DAY_IN_MINUTES) : 1;
        res.options.protected_role_ids = automation.options.protected_role_ids || [];
        res.groups = [];
        res.protectedUsers = [];
        res.options.group_ids = automation.options.group_ids || [];
        res.options.isNotifyBefore = Boolean(res.options.notify_before) || res.options.notify_users;
      }
      if (automation.action === 'file_move' || automation.action === 'file_copy') {
        res.minAge = Math.round(res.options.min_age_minutes / DAY_IN_MINUTES) || '';
        res.startDate = moment(automation.options.start_date * 1000).format(MOMENT_FORMATS.DATE);
        res.options.use_pattern = Boolean(automation.options.use_pattern);
        res.options.use_min_age = Boolean(automation.options.use_min_age);
        res.options.on_conflict = automation.options.on_conflict || ON_CONFLICT_DEFAULT;
      }
      if (automation.action === 'file_move') {
        res.options.rename = Boolean(automation.options.rename_on_move);
      }
      if (automation.action === 'file_copy') {
        res.options.rename = Boolean(automation.options.rename);
      }
      res.isActive = automation.status === 'A';
      res.isDisabledState = false;
      res.isCheckedState = false;
      res.isActiveState = false;
      res.tags = automation.tags;
      return res;
    },
    getAttributes: (state, getters, rootState, rootGetters) => (automation) => {
      // NOTE: the automation is passed by value (like a deep copy), so the
      // delete operator is NOT affecting the outside world.
      const isNotifyBefore = automation.action === 'user_autodelete' ? automation.options.isNotifyBefore : false;
      if (automation.action === 'user_autodelete') {
        delete automation.options.isNotifyBefore;
      }

      const renameOnMove = automation.action === 'file_move' ? automation.options.rename : undefined;
      if (automation.action === 'file_move') {
        delete automation.options.rename;
        delete automation.options.on_conflict;
      }

      return {
        ...{ ids: automation.ids },
        ...{ notes: automation.notes },
        ...{ action: automation.action },
        ...(automation.file_id && { file_id: automation.file_id }),
        ...(automation.email && { email: automation.email }),
        ...(automation.name && { name: automation.name }),
        ...(automation.options && {
          options: {
            ...automation.options,
            ...(automation.action === 'autodelete' && {
              ...(automation.options.expire && { expire: Number(automation.options.expire) }),
            }),
            ...(automation.action === 'contact_autodelete' && {
              ...(automation.inactiveDays && { last_usage_threshold: automation.inactiveDays * DAY_IN_MINUTES }),
              ...(automation.protectSiteContact !== undefined && {
                protected_group_ids: automation.protectSiteContact ? [rootGetters['groups/siteGroup'].id] : [],
              }),
            }),
            ...(automation.action === 'user_autodelete' && {
              ...(automation.inactiveDays && { last_login_threshold: automation.inactiveDays * DAY_IN_MINUTES }),
              ...(isNotifyBefore &&
                automation.notifyBefore && { notify_before: automation.notifyBefore * DAY_IN_MINUTES }),
              ...(!isNotifyBefore && {
                notify_users: false,
                notify_before: null,
              }),
            }),
            ...((automation.action === 'file_move' || automation.action === 'file_copy') && {
              ...(automation.minAge && { min_age_minutes: automation.minAge * DAY_IN_MINUTES }),
            }),
            ...(automation.action === 'file_move' && {
              ...(renameOnMove !== undefined && { rename_on_move: renameOnMove }),
            }),
          },
        }),
        ...(typeof automation.isActive !== 'undefined' && { status: automation.isActive ? 'A' : 'D' }),
      };
    },
    getAutomationById: (state) => (id) => {
      return Object.values(state.automationsList)
        .flat()
        .find((automation) => automation.id === id);
    },
    getFilteredList: (state) => (automationKey) => {
      return state.automationsList[automationKey] || [];
    },
    getTmpModel: (state) => (key) => {
      switch (key) {
        case 'autodelete':
          return cloneDeep(AUTODELETE_MODEL);
        case 'activity_export':
          return cloneDeep(ACTIVITY_EXPORT_MODEL);
        case 'contact_autodelete':
          return cloneDeep(AUTODELETE_CONTACTS_MODEL);
        case 'user_autodelete':
          return cloneDeep(AUTODELETE_USERS_MODEL);
        case 'file_operation':
          return cloneDeep(FILES_OPERATION_MODEL);
        case 'external_file_operation':
          return cloneDeep(EXTERNAL_AUTOMATION_MODEL);
        default:
          return {};
      }
    },
  },
  mutations: {
    setAutomations(state, { automations, automationKey }) {
      state.automationsList[automationKey] = automations;
    },
    addAutomation(state, { automation, automationKey }) {
      state.automationsList[automationKey].push(automation);
    },
    updateAutomations(state, { automations, automationKey }) {
      automations.forEach((automation) => {
        const automationIndex = state.automationsList[automationKey].findIndex((item) => item.id === automation.id);
        if (automationIndex !== -1) {
          const { isActiveState, isCheckedState, isDisabledState } =
            state.automationsList[automationKey][automationIndex];
          const updatedAutomation = { ...automation, isCheckedState, isActiveState, isDisabledState };
          state.automationsList[automationKey].automationIndex = updatedAutomation;
        } else {
          state.automationsList[automationKey].push(automation);
        }
      });
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
    addTaskStatus(state, taskId) {
      state.automationsList.manual_stopping.push(taskId);
    },
    removeTaskStatus(state, taskId) {
      state.automationsList.manual_stopping = state.automationsList.manual_stopping.filter((e) => e !== taskId);
    },
    updateAutomationTags(state, { automationId, tags }) {
      const automation = Object.values(state.automationsList)
        .flat()
        .find((automation) => automation.id === automationId);
      if (automation) {
        automation.tags = tags;
      }
    },
  },
  actions: {
    async getAutomations({ getters, commit }, automationKey) {
      // NOTE: abstract away the automationKey -> automationTypes translation,
      // if more cases show up here.
      const automationTypes = automationKey === 'file_operation' ? 'file_move,file_copy' : automationKey;
      const { data } = await getAutomationList(automationTypes);
      const automations = data.map(getters.parse);
      commit('setAutomations', { automations, automationKey });
    },
    async getAutomation({ getters, commit }, options = {}) {
      const { data } = await getAutomationMetadata(options.id);
      const automation = getters.parse(data);
      commit('updateAutomations', { automations: [automation], automationKey: options.automationKey });
      return automation;
    },
    async createAutomation({ getters, commit }, options = {}) {
      const { data } = await createAutomation(getters.getAttributes(options.data));
      const automation = getters.parse(data || []);
      commit('addAutomation', { automation, automationKey: options.automationKey });
      return automation;
    },
    async editAutomations({ getters, commit }, options = {}) {
      const { data } = await updateAutomation(getters.getAttributes(options.data));
      const automations = data.map(getters.parse);
      commit('updateAutomations', { automations, automationKey: options.automationKey });
      return automations;
    },
    async deleteAutomations(context, options = {}) {
      await deleteAutomation({ ids: options.ids });
    },
    updAutomationTaskStatus({ commit }, options = {}) {
      if (options.remove) {
        commit('removeTaskStatus', options.taskToUpd);
      } else {
        commit('addTaskStatus', options.taskToUpd);
      }
    },
  },
};
