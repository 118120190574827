import moment from 'moment';
import { getProfile, updateProfile, fetchApiKeyList } from '@/api';

import gettext from '@/utils/translationInjector.js';
const { $gettext } = gettext;

const getInitialState = () => ({
  id: null,
  uid: null,
  isSuperAdmin: false,
  operationsCode: 0,
  operations: {},
  maxFileSize: null,
  filesPerShare: null,
  accountStatus: 'A',
  accountPlan: '',
  accountExpireDate: 0,
  homeId: '',
  shareTypes: {},
  isPGPEnabled: false,
  services: [],
  hasPGPKey: false,
  incomingId: '',
  outgoingId: '',
  messageSignature: '',
  authMethods: [],
  forcedAuth: [],
  sftpLogin: '',
  sftpURL: '',
  uniqueLogin: '',
  share_settings: {
    expiry_date_limit: null,
    request_message: '',
    share_message: '',
    send_file_request_email: false,
    download_notify: false,
    return_files: false,
    return_files_force_registration_by_default: false,
  },
  homePrefix: null,
  userLimit: 0,
  storageLimit: 0,
  totalReservations: 0,
  isWopiActive: true,
  isAgentTab: false,
  responseTime: null,
  apiKeyList: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    getProfileData: (state) => (profile) => {
      if (!profile) {
        throw new Error('Incorrect payload');
      }
      return {
        ...(profile.name && { name: profile.name }),
        ...(state.isSuperAdmin && profile.email && { email: profile.email.toLowerCase() }),
        ...((profile.message_signature || profile.message_signature === '') && {
          message_signature: profile.message_signature,
        }),
        ...(profile.language && { language: profile.language }),
      };
    },
    checkOperations:
      (state, getters, rootState, rootGetters) =>
      (...permissions) => {
        return rootGetters['operations/check']({ operations: state.operations, permissions });
      },
  },
  mutations: {
    setProfile(state, profile) {
      state.id = profile.id;
      state.uid = profile.uid;
      state.isSuperAdmin = profile.super_admin === 'Y';
      if (profile.operations !== undefined) {
        state.operationsCode = profile.operations;
        state.operations = profile.parsedOperations;
      }
      state.maxFileSize = profile.max_file_size < 0 ? null : parseInt(profile.max_file_size, 10);
      state.accountStatus = profile.account_status;
      state.accountPlan = profile.plan;
      state.accountExpireDate = profile.expire_date;
      state.homeId = profile.home_id || '';
      state.services = profile.services;
      state.incomingId = profile.incoming_id;
      state.outgoingId = profile.outgoing_id;
      state.forcedAuth = profile.forced_auth;
      state.sftpLogin = profile.sftp_login;
      state.sftpURL = profile.sftp_url;
      state.uniqueLogin = profile.unique_login;
      state.share_settings = {
        expiry_date_limit: profile.share_settings?.expiry_date_limit,
        request_message: profile.share_settings?.request_message?.replaceAll('<br/>', '\n') || '',
        share_message: profile.share_settings?.share_message?.replaceAll('<br/>', '\n') || '',
        send_file_request_email: profile.share_settings?.send_file_request_email,
        download_notify: profile.share_settings?.download_notify,
        return_files: profile.share_settings?.return_files,
        return_files_force_registration_by_default: Boolean(
          profile.share_settings?.return_files_force_registration_by_default
        ),
      };
      if (profile.wopi_enabled !== undefined) {
        state.isWopiActive = profile.wopi_enabled;
      }
      state.isAgentTab = Boolean(profile.show_agent_tab);
      state.isExternalAutomationEnabled =
        profile.external_automation_enabled !== undefined ? profile.external_automation_enabled : true; // temporary it's true for everyone
      state.isShowAllPfMembers = Boolean(profile.show_all_pf_members); // true = show autocomplete on create/edit PF on input focus
    },
    setResponseTime(state, time) {
      state.responseTime = time;
    },
    setMessageSignature(state, message_signature) {
      state.messageSignature = message_signature;
    },
    setPGPState(state, pgp_state) {
      state.isPGPEnabled = pgp_state;
    },
    setPGPKeys(state, has_key) {
      state.hasPGPKey = has_key;
    },
    setAuthMethods(state, auth_methods) {
      state.authMethods = auth_methods;
    },
    setUserLimits(state, limits) {
      state.userLimit = parseInt(limits.user_limit, 10);
      state.storageLimit = parseInt(limits.storage_limit, 10);
      state.totalReservations = parseInt(limits.total_reservations, 10);
    },
    setHomePrefix(state, prefix) {
      state.homePrefix = prefix;
    },
    setFilesPerShare(state, filesPerShare) {
      state.filesPerShare = parseInt(filesPerShare, 10);
    },
    setApiKeyList(state, list) {
      state.apiKeyList = list;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getProfile({ commit, dispatch, rootGetters }) {
      try {
        const response = await getProfile();
        const resp = response.data;

        commit('setProfileName', resp.name, { root: true });

        commit('auth/setUserEmail', resp.email, { root: true });
        commit('setLoggedAsContact', resp.is_contact, { root: true });
        commit('setLanguage', resp.language, { root: true });
        commit('setPGPState', resp.pgp_enabled);
        commit('setPGPKeys', resp.has_key);
        commit('setMessageSignature', resp.message_signature);
        commit('setAuthMethods', resp.auth_methods);
        commit('share/setActiveShareTypes', resp.share_types || {}, { root: true });
        commit('setHomePrefix', resp.template);
        commit('setFilesPerShare', resp.max_files_per_share);
        dispatch('setupThirdPartExtensions', resp);
        if (resp.operations !== undefined) {
          resp.parsedOperations = rootGetters['operations/parse']('user', resp.operations);
          if (!resp.parsedOperations.manage_billing && rootGetters.isSuspended) {
            commit('setErrorPageKey', 'errorSuspended', { root: true });
          }
        }
        commit('setProfile', resp);
        commit('files/setRootModel', resp.home_id, { root: true });
        commit('setResponseTime', moment(response.headers.date)); // for correct expiration limit
        commit('auth/setIsLoggedIn', true, { root: true });
        return resp;
      } catch (error) {
        commit('auth/setIsLoggedIn', false, { root: true });
        if (error.response?.status === 404) {
          dispatch('logout', undefined, { root: true });
        }
        throw error;
      }
    },
    async setProfile({ getters, commit }, payload) {
      const { data } = await updateProfile(getters.getProfileData(payload));
      commit('setProfileName', data.name, { root: true });
      commit('auth/setUserEmail', data.email, { root: true });
      commit('setLanguage', data.language, { root: true });
      commit('setMessageSignature', data.message_signature);
      return data;
    },
    setupThirdPartExtensions(store, profile) {
      if (typeof Sentry !== 'undefined') {
        Sentry.setUser({
          email: profile.email,
          id: profile.id,
          data: profile,
        });
      }
    },
    async getApiKeyList({ commit }) {
      const { data = [] } = await fetchApiKeyList();
      commit('setApiKeyList', data || []);
    },
  },
};
