import axios from 'axios';

import { http } from './httpModule.js';
import { mfaLoginInterceptor, mfaLoginErrorInterceptor, lookupInterceptor } from './inteceptors';
// Profile

export const getProfile = () => http.get('/profile', { validateStatus: (status) => status === 200 });

export const updateProfile = (payload) =>
  http.post('/profile/set', payload, { validateStatus: (status) => status === 200 });

export const get2faCodes = () => http.get('/profile/2fa/get-code', { validateStatus: (status) => status === 200 });

export const generate2fa = () => http.get('/profile/2fa/generate', { validateStatus: (status) => status === 200 });

export const setPassword = (data) =>
  http.post('/profile/set-password', data, { validateStatus: (status) => status === 200 });

export const getProfileInfo = () => http.get('/profile/info', { validateStatus: (status) => status === 200 });

// Session

export const logout = (params) =>
  http.get('/session/logout', { params, validateStatus: (status) => [200, 404].includes(status) });

export const accountMetadata = () => http.get('/account/metadata', { validateStatus: (status) => status === 200 });

export const getLanguageList = () => http.get('/languages', { validateStatus: (status) => status === 200 });

export const getDefaultAccountLang = () =>
  http.get('/languages/default', payload, { validateStatus: (status) => status === 200 });

export const signIn = (token, show_tour) =>
  http
    .get('/session/login', {
      headers: {
        Authorization: `Basic ${token}`,
      },
      params: {
        ...(show_tour && { show_tour }),
        sb: 1,
      },
      validateStatus: (status) => [200, 207].includes(status),
    })
    .then(mfaLoginInterceptor)
    .catch(mfaLoginErrorInterceptor);

export const mfaLogin = (data) =>
  http
    .post('/session/login', data, {
      params: { sb: 1 },
      validateStatus: (status) => [200, 207].includes(status),
    })
    .then(mfaLoginInterceptor)
    .catch(mfaLoginErrorInterceptor);

export const unblockCaptcha = (payload) =>
  http.post('/session/unblock-captcha', payload, { validateStatus: (status) => status === 200 });

export const keepAlive = () => http.get('/session/keepalive');

// MQ

// Get statuses of multiple jobs with IDs form the given array. For completed
// jobs, notification payload is also returned (the same payload that goes
// trough MQ).
export const getJobStatuses = (ids) =>
  http.post('/job/status', { ids }, { validateStatus: (status) => status === 200 });

// Download page

export const getRecipients = () => http.get('/share/recipients', { validateStatus: (status) => status === 200 });

export const createShare = (payload) =>
  http.post('/share/create', payload, { validateStatus: (status) => status === 201 });

export const shareRequestFiles = (payload) =>
  http.post('/share/request', payload, { validateStatus: (status) => status === 200 });

export const fetchDownloadPageMetadata = (id) =>
  http.get(`/share/download-info/${id}`, { validateStatus: (status) => status === 200 });

export const fetchDownloadAllFilesLink = (id) =>
  http.get(`/share/download-link/${id}`, { validateStatus: (status) => status === 200 });

export const fetchAccountsList = () => http.get('/account/list');

export const fetchFiles = (id) => http.get(`/share/files/${id}`, { validateStatus: (status) => status === 200 });

export const fetchDownloadLink = (id, payload) =>
  http.post(`/share/download-link/${id}`, { files: payload }, { validateStatus: (status) => status === 200 });

export const sharePin = (data) => http.post('/share/login-pin/', data, { validateStatus: (status) => status === 200 });

export const shareLookup = (id, token) =>
  http
    .get(`/share/lookup/${id}`, {
      headers: {
        Authorization: `Basic ${token}`,
      },
      validateStatus: (status) => status === 200,
    })
    .then(lookupInterceptor);

// Key request page

export const fetchKeyRequestMetadata = (id) =>
  http.get(`/key-request/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const sendKeys = (id, payload) =>
  http.post(`/key-request/respond/${id}`, payload, { validateStatus: (status) => status === 202 });

export const keyRequestLookup = (id, token) =>
  http
    .get(`/key-request/lookup/${id}`, {
      headers: {
        Authorization: `Basic ${token}`,
      },
      validateStatus: (status) => status === 200,
    })
    .then(lookupInterceptor);

// Quarantine

export const getVirusFiles = () => http.get('/virusscan/files', { validateStatus: (status) => status === 200 });

export const markSafeVirusFiles = (payload) =>
  http.post('/virusscan/restore', payload, { validateStatus: (status) => status === 200 });

export const getVirusNotifyEmails = () => http.get('/virusscan/emails', { validateStatus: (status) => status === 200 });

export const editVirusNotifyEmails = (payload) =>
  http.post('/virusscan/emails', payload, { validateStatus: (status) => status === 200 });

// SSO

export const getSSOList = () => http.get('/idp/');

export const deleteSSO = (id) => http.get(`/idp/delete/${id}`);

export const parseSSOMetadata = (payload) => http.post('/idp/parse-metadata', payload);

export const createSSO = (payload) => http.post('/idp/create', payload);

export const fetchSSOItem = (id) => http.get(`/idp/${id}`);

export const updateSSOItem = (id, payload) => http.post(`/idp/update/${id}`, payload);

// Return files page

export const fetchReturnPageMetadata = (id) =>
  http.get(`/files-return/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const fetchReturnUploadLink = (id, payload) =>
  http.post(`/files-return/upload-link/${id}`, payload, { validateStatus: (status) => status === 200 });

export const setReturnSent = (payload) =>
  http.post('/files-return/send', payload, { validateStatus: (status) => status === 201 });

export const makeFolderAtReturn = (id, payload) =>
  http.post(`/files-return/makedir/${id}`, payload, { validateStatus: (status) => status === 201 });

export const returnFilesLookup = (id, token) =>
  http
    .get(`/files-return/lookup/${id}`, {
      headers: {
        Authorization: `Basic ${token}`,
      },
      validateStatus: (status) => status === 200,
    })
    .then(lookupInterceptor);

// Tracking

export const getInbox = (params) => http.get('/inbox', { params, validateStatus: (status) => status === 200 });

export const getActivity = (params) =>
  http.get('/tracking/activity', { params, validateStatus: (status) => status === 200 });

export const getTrackingCSV = (params) =>
  http.get('/tracking/csv', { params, validateStatus: (status) => status === 200 });

export const fetchTracking = (id) => http.get(`/tracking/${id}`);

export const fetchTrackingListById = (id, params) => http.get(`/tracking/list/${id}`, { params });

export const fetchTrackingList = (params) => http.get('/tracking/list', { params });

export const fetchTrackingFiles = (id) => http.get(`/tracking/files/${id}`);

export const fetchTrackingMetadata = (id) => http.get(`/tracking/metadata/${id}`);

export const fetchTrackingDownload = (id) => http.get(`/tracking/downloads/${id}`);

export const revokeQuickLink = (id) =>
  http.get(`/quicklink/revoke/${id}`, { validateStatus: (status) => status === 200 });

export const createQuicklink = ({ files, subject }) =>
  http.post('/quicklink/create', { files, subject }, { validateStatus: (status) => status === 201 });

export const revokeShare = (id) => http.get(`/share/revoke/${id}`, { validateStatus: (status) => status === 200 });

export const getTrackingUserList = () => http.get('/tracking/user', { validateStatus: (status) => status === 200 });

// Upload

export const finalizeChunkedUpload = (id) =>
  http.get(`/upload/finalize/${id}`, { validateStatus: (status) => status === 200 });

export const fetchUploadLink = (name, parentId, size) =>
  http.post(
    '/upload/link',
    {
      parent_id: parentId,
      name,
      upload_type: 'chunked',
      file_size: size,
    },
    { validateStatus: (status) => status === 200 }
  );

// API KEYS

export const fetchApiKeyList = () => http.get('/api-key/');

export const deleteApiKey = (id) => http.get(`/api-key/delete/${id}`);

export const createApiKey = (payload) => http.post('/api-key/create/', payload);

export const fetchApiKey = (id) => http.get(`/api-key/metadata/${id}`);

export const updateApiKey = (id, payload) => http.post(`/api-key/update/${id}`, payload);

// Search

export const fileSearch = (query, config) => http.post('/file/search/', query, config);

// Files

export const fileDelete = (payload) => http.post('/file/delete/', payload);

export const makeFolder = (payload) =>
  http.post('/file/makedir/', payload, { validateStatus: (status) => status === 201 });

export const emptyTrash = (id, params) =>
  http.post(`/file/empty-trash/${id}`, {}, { params, validateStatus: (status) => [200, 202].includes(status) });

// API returns metadata with content by default.
export const getFileMetadata = (id, withContent = true) =>
  http.get(`/file/metadata/${id}${withContent ? '' : '?content=0'}`, { validateStatus: (status) => status === 200 });

export const getCommentsInFolder = (folderId) =>
  http.get(`/file/comment/parent/${folderId}`, { validateStatus: (status) => status === 200 });

export const getFileComments = (fileId) =>
  http.get(`/file/comment/${fileId}`, { validateStatus: (status) => status === 200 });

export const setComment = (fileId, data) =>
  http.post(`/file/comment/${fileId}`, data, { validateStatus: (status) => status === 200 });

export const getBreadcrumbs = (fileId) =>
  http.get(`/file/breadcrumbs/${fileId}`, { validateStatus: (status) => status === 200 });

export const getDownloadLink = (payload) =>
  http.post('/file/download-link', payload, { validateStatus: (status) => status === 200 });

export const copyFile = (payload) => http.post('/file/copy', payload, { validateStatus: (status) => status === 202 });

export const moveFile = (payload) => http.post('/file/move', payload);

export const renameFile = (id, name) =>
  http.post(`/file/rename/${id}`, { name }, { validateStatus: (status) => status === 200 });

export const deleteFile = (ids) => http.post('/file/delete', { ids });

export const getFilePreview = (id, isSharePreview = false) => {
  const url = isSharePreview ? `/share/preview/${id}/info` : `/file/preview/${id}`;
  return http.get(url, {
    validateStatus: (status) => status === 200 || status === 201,
  });
};

export const getFileInfo = (id) => http.get(`/file/info/${id}`, { validateStatus: (status) => status === 200 });

export const getFolderSize = (id) => http.get(`/file/size/${id}`, { validateStatus: (status) => status === 200 });

// Comments

export const editComment = (commentId, data) =>
  http.post(`comment/edit/${commentId}`, data, { validateStatus: (status) => status === 200 });

export const deleteComment = (commentId) =>
  http.post(`comment/delete/${commentId}`, {}, { validateStatus: (status) => status === 200 });

// Invoices

export const getInvoices = () => http.get('/invoice', { validateStatus: (status) => status === 200 });

export const getInvoiceMetadata = (id) =>
  http.get(`/invoice/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const payInvoice = (id, payload) =>
  http.post(`/invoice/pay/${id}`, payload, { validateStatus: (status) => status === 200 });

export const payInvoiceByNewCard = (id, payload) =>
  http.post(`/invoice/card-pay/${id}`, payload, { validateStatus: (status) => status === 200 || status === 307 });

// Billing

export const purchaseUpgrade = (payload) =>
  http.post('/purchase/upgrade/', payload, { validateStatus: (status) => status === 200 });

export const fetchPurchaseMetadata = () => http.get('/purchase/metadata/');

export const getPackagesForUpgrade = () => http.get('/package/');

export const countPrice = (data) => http.post('/purchase/calc-price/', data);

// Cards
export const getCards = () => http.get('/credit-card/', { validateStatus: (status) => status === 200 });

export const getCard = (id) => http.get(`/credit-card/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const createCard = (payload) =>
  http.post('/credit-card/create', payload, { validateStatus: (status) => status === 200 || status === 307 });

export const editCard = (id, payload) =>
  http.post(`/credit-card/edit/${id}`, payload, { validateStatus: (status) => status === 200 });

export const deleteCard = (id) =>
  http.post(`/credit-card/delete/${id}`, { validateStatus: (status) => status === 200 });

// Billing
export const getAutoRenewal = () => http.get('/purchase/auto-renewal/', { validateStatus: (status) => status === 200 });

export const setAutoRenewal = (data) =>
  http.post('/purchase/auto-renewal/', data, { validateStatus: (status) => status === 200 });

// MSFT
export const getWopiToken = (id, params) =>
  http.get(`/file/wopi-token/${id}`, { validateStatus: (status) => status === 200, params });

// File tags
export const getTagsForFile = (id) =>
  http
    .get(`/file/tags/${id}`, { validateStatus: (status) => status === 200 })
    .then(({ data }) => (data || []).filter((tag) => tag.type === 'text'));

export const getTagsForPath = (id) =>
  http.post(
    `/file/tags/parent/${id}`,
    {
      tag_types: ['text'],
    },
    { validateStatus: (status) => status === 200 }
  );

export const getTagsForAutocomplete = () => http.get('/tags/list', { validateStatus: (status) => status === 200 });

export const deleteTagFromFile = (id) =>
  http.post(`/tags/delete/${id}`, { validateStatus: (status) => status === 200 });

export const addTagToFile = (id, value) =>
  http.post(
    `/file/add-tag/${id}`,
    {
      type: 'text',
      value,
    },
    { validateStatus: (status) => status === 200 }
  );
// Project-folders

export const getProjectFolderList = () => http.get('/project-folder', { validateStatus: (status) => status === 200 });

export const createProjectFolder = (payload) =>
  http.post('/project-folder/create', payload, { validateStatus: (status) => status === 201 });

export const getUsersProjectFolders = (ids) =>
  http.post('/project-folder/projects-users', { ids }, { validateStatus: (status) => status === 200 });

export const setUsersProjectFolder = (users) =>
  http.post('/project-folder/set-users', { users }, { validateStatus: (status) => status === 200 });

export const getProjectFolderUsers = (id) =>
  http.get(`/project-folder/users/${id}`, { validateStatus: (status) => status === 200 });

export const addProjectFolderUsers = (id, payload) =>
  http.post(`/project-folder/add-users/${id}`, payload, { validateStatus: (status) => status === 200 });

export const editProjectFolderUsers = (id, payload) =>
  http.post(`/project-folder/edit-users/${id}`, payload, { validateStatus: (status) => status === 200 });

export const editProjectFolderMetadata = (id, payload) =>
  http.post(`/project-folder/edit/${id}`, payload, { validateStatus: (status) => status === 200 });

export const deleteProjectFolderUsers = (users) =>
  http.post('/project-folder/delete-users/', { users }, { validateStatus: (status) => status === 200 });

export const deleteProjectFolder = (id) =>
  http.get(`/project-folder/delete/${id}`, { validateStatus: (status) => status === 200 });

export const getProjectFolderMetadata = (id) =>
  http.get(`/project-folder/metadata/${id}`, { validateStatus: (status) => status === 200 });

// Service settings

export const getSiteSettings = () => http.get('/settings', { validateStatus: (status) => status === 200 });

export const updateSiteSettings = (payload) =>
  http.post('/settings/set', payload, { validateStatus: (status) => status === 200 });

export const getAuthMethods = () => http.get('/settings/auth-methods', { validateStatus: (status) => status === 200 });

export const getAccountLogoUploadLink = () =>
  http.get('/settings/upload-logo-link', { validateStatus: (status) => [200, 201].includes(status) });

export const uploadAccountImage = (id, payload) =>
  http.post(`/upload/image/${id}`, payload, { baseURL: '/', validateStatus: (status) => status === 200 });

// SSH keys

export const createSsh = (payload) =>
  http.post('/ssh-key/create', payload, { validateStatus: (status) => status === 201 });

export const getSshMetadata = (id) =>
  http.get(`/ssh-key/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const getSshKeys = () => http.get('/ssh-key/', { validateStatus: (status) => status === 200 });

export const getUsersSsh = (id) => http.get(`/ssh-key/${id}`, { validateStatus: (status) => status === 200 });

export const editSsh = (id, payload) =>
  http.post(`/ssh-key/edit/${id}`, payload, { validateStatus: (status) => status === 200 });

export const deleteSsh = (id) => http.post(`/ssh-key/delete/${id}`, { validateStatus: (status) => status === 200 });

// Services

export const getServices = () => http.get('service', { validateStatus: (status) => status === 200 });

export const getServiceMetadata = (id) =>
  http.get(`/service/metadata/${id}`, { validateStatus: (status) => status === 200 });

// MFA
export const getMfa = (id) => http.get(`/user/mfa/${id}`, { validateStatus: (status) => status === 200 });

export const setMfa = (setFor, payload, signal) =>
  http.post(`/${setFor}/set-mfa`, payload, { signal, validateStatus: (status) => status === 200 });

export const resetMfa = (setFor, payload, signal) =>
  http.post(`/${setFor}/reset-mfa`, payload, { signal, validateStatus: (status) => status === 200 });

export const removeMfa = (setFor, payload) =>
  http.post(`/${setFor}/remove-mfa`, payload, { validateStatus: (status) => status === 200 });

// Branding

export const getBrandingSettings = () => http.get('/branding/');

export const editBrandingSettings = (payload) => http.post('/branding/', payload);

export const previewBranding = (payload) => http.post('/branding/preview', payload);

// SubFolder permissions

export const getSubFolderPermissions = (id) =>
  http.get(`/file/permissions/${id}`, { validateStatus: (status) => status === 200 });

export const setSubFolderPermissions = (id, payload) =>
  http.post(`/file/permissions/${id}`, payload, { validateStatus: (status) => status === 200 });

// PGP

export const requestPgpKey = (ids) =>
  http.post('/pgp-key/request', { ids }, { validateStatus: (status) => status === 202 });

// Automations

export const getAutomationList = (automationKey) =>
  http.get(`/automation${automationKey ? '?actions=' + automationKey : ''}`, {
    validateStatus: (status) => status === 200,
  });

export const getAutomationMetadata = (id) =>
  http.get(`/automation/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const createAutomation = (data) =>
  http.post('/automation/create', data, { validateStatus: (status) => status === 201 });

export const updateAutomation = (data) =>
  http.post('/automation/edit', data, { validateStatus: (status) => status === 200 });

export const deleteAutomation = (data) =>
  http.post('/automation/delete', data, { validateStatus: (status) => status === 200 });

export const getAutomationSchedules = (id) =>
  http.get(`/automation/schedule/${id}`, { validateStatus: (status) => status === 200 });

export const addAutomationSchedule = (id, payload) =>
  http.post(`/automation/schedule/${id}`, payload, { validateStatus: (status) => status === 200 });

export const runAutomationOnce = (id) =>
  http.post(`/automation/run/${id}`, {}, { validateStatus: (status) => status === 202 });

export const getAutomationRemotes = () =>
  http.get('/automation/remote', { validateStatus: (status) => status === 200 });

export const getRemoteFolders = (id, payload) =>
  http.post(`/automation/remote/folders/${id}`, payload, { validateStatus: (status) => status === 200 });

export const getAutomationRunHistory = (payload, signal) =>
  http.post('/automation/run-history', payload, { signal, validateStatus: (status) => status === 200 });

export const getAutomationRunMetadata = (id) =>
  http.get(`/automation/run-history/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const stopAutomationJobs = (ids) =>
  http.post('/job/stop', { ids }, { validateStatus: (status) => status === 200 });

export const addAutomationTag = (id, value) =>
  http.post(
    `/automation/tags/add/${id}`,
    {
      type: 'string',
      value,
    },
    { validateStatus: (status) => status === 200 }
  );

export const getAutomationTagsForAutocomplete = () =>
  http.get('/automation/tags', { validateStatus: (status) => status === 200 });

export const deleteTagFromAutomation = (id) =>
  http.post(`/automation/tags/delete/${id}`, { validateStatus: (status) => status === 200 });

// Scheduler

export const updateSchedule = (id, payload) =>
  http.post(`/schedule/edit/${id}`, payload, { validateStatus: (status) => status === 200 });

export const deleteSchedule = (id) =>
  http.post(`/schedule/delete/${id}`, {}, { validateStatus: (status) => status === 200 });

export const getScheduleOverview = () => http.get('/schedule/overview', { validateStatus: (status) => status === 200 });

// Reset password page

export const passwordResetMetadata = (id) =>
  http.get(`/reset-password/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const saveResetPassword = (id, payload) =>
  http.post(`/reset-password/reset/${id}`, payload, { validateStatus: (status) => status === 200 });

export const requestPasswordReset = (email) =>
  http.post('/reset-password/request', { email }, { validateStatus: (status) => [200, 202].includes(status) });

// Share

export const getRecipientsKeys = (ids) =>
  http.post('/pgp-key/recipients', { ids }, { validateStatus: (status) => status === 200 });

// Remote sites

export const getRemoteSiteList = () => http.get('/remote-site', { validateStatus: (status) => status === 200 });

export const getRemoteSite = (id, params = {}) =>
  http.get(`/remote-site/metadata/${id}`, { params, validateStatus: (status) => status === 200 });

export const createRemoteSite = (data) =>
  http.post('/remote-site/create', data, { validateStatus: (status) => status === 201 });

export const updateRemoteSite = (id, data) =>
  http.post(`/remote-site/edit/${id}`, data, { validateStatus: (status) => status === 200 });

export const deleteRemoteSites = (ids) =>
  http.post('/remote-site/delete', { ids }, { validateStatus: (status) => status === 200 });

export const runTestConnection = (id) =>
  http.post(`/remote-site/test-connection/${id}`, {}, { validateStatus: (status) => status === 202 });

export const testRemoteConnectionByPayload = (payload) =>
  http.post('/remote-site/test-connection', payload, { validateStatus: (status) => status === 202 });

export const oauthStartAuthorization = (payload) =>
  http.post('/remote-site/oauth-start', payload, { validateStatus: (status) => status === 200 });

export const getDataTypeConfig = (id, payload) =>
  http.post(`/remote-site/configure/${id}`, payload, { validateStatus: (status) => status === 200 });

export const getRemotePermissions = (id) =>
  http.get(`/remote-site/permissions/${id}`, { validateStatus: (status) => status === 200 });

export const updateRemotePermissions = (id, payload) =>
  http.post(`/remote-site/edit-permissions/${id}`, payload, { validateStatus: (status) => status === 200 });

export const renewRemoteToken = (id) =>
  http.post(`/remote-site/renew-token/${id}`, {}, { validateStatus: (status) => status === 200 });

// Remote agents

export const getAgentsList = () => http.get('/agent', { validateStatus: (status) => status === 200 });

export const getAgent = (id) => http.get(`/agent/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const approveAgent = (id, data) =>
  http.post(`/agent/approve/${id}`, data, { validateStatus: (status) => status === 200 });

export const rejectAgent = (id) => http.post(`/agent/reject/${id}`, {}, { validateStatus: (status) => status === 200 });

export const updateAgent = (id, data) =>
  http.post(`agent/edit/${id}`, data, { validateStatus: (status) => status === 200 });

export const deleteAgent = (id) => http.post(`agent/delete/${id}`, {}, { validateStatus: (status) => status === 200 });

export const getAgentsPools = () => http.get('/agent/pool', { validateStatus: (status) => status === 200 });

export const createAgentsPool = (payload) =>
  http.post('/agent/pool/create', payload, { validateStatus: (status) => status === 201 });

export const editAgentsPool = (id, payload) =>
  http.post(`/agent/pool/edit/${id}`, payload, { validateStatus: (status) => status === 200 });

export const getAgentsPool = (id) => http.get(`/agent/pool/${id}`, {}, { validateStatus: (status) => status === 200 });

export const deleteAgentsPool = (id) =>
  http.post(`/agent/pool/delete/${id}`, {}, { validateStatus: (status) => status === 200 });

// Account

export const getAccountInfo = () => http.get('/account/info', { validateStatus: (status) => status === 200 });

export const getAccountRoles = () => http.get('/account/roles', { validateStatus: (status) => status === 200 });

// Contact

export const getContacts = () => http.get('/contact', { validateStatus: (status) => status === 200 });

export const getContactMetadata = (id) =>
  http.get(`/contact/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const convertContact = (contactId, payload) =>
  http.post(`/contact/upgrade/${contactId}`, payload, { validateStatus: (status) => status === 200 });

export const getContactGroups = () => http.get('/contact/group', { validateStatus: (status) => status === 200 });

export const createContact = (payload) =>
  http.post('/contact/create', payload, { validateStatus: (status) => status === 201 });

export const updateContact = (id, payload) =>
  http.post(`/contact/edit/${id}`, payload, { validateStatus: (status) => status === 200 });

export const deleteContacts = (ids) =>
  http.post('/contact/delete', { ids }, { validateStatus: (status) => status === 200 });

// User

export const getUsers = () => http.get('/user', { validateStatus: (status) => status === 200 });

export const getUserMetadata = (id) => http.get(`/user/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const createUser = (payload) =>
  http.post('/user/create', payload, { validateStatus: (status) => status === 201 });

export const editUser = (payload) => http.post('/user/edit', payload, { validateStatus: (status) => status === 200 });

export const deleteUser = (payload) =>
  http.post('/user/delete', payload, { validateStatus: (status) => status === 202 });

export const getUserGroups = () => http.get('/user/group', { validateStatus: (status) => status === 200 });

export const getUserPgpKeys = (id) => http.get(`/user/pgp-key/${id}`, { validateStatus: (status) => status === 200 });

// PGP Keys Management

export const deletePgpKey = (id) => http.get(`/pgp-key/delete/${id}`, { validateStatus: (status) => status === 200 });

export const updatePgpKey = (id, payload) =>
  http.post(`/pgp-key/edit/${id}`, payload, { validateStatus: (status) => status === 200 });

export const createPgpKey = (payload) =>
  http.post('/pgp-key/create', payload, { validateStatus: (status) => status === 201 });

// Group

export const getGroups = () => http.get('/group', { validateStatus: (status) => status === 200 });

export const getGroup = (id) => http.get(`/group/metadata/${id}`, { validateStatus: (status) => status === 200 });

export const createGroup = (payload) =>
  http.post('/group/create', payload, { validateStatus: (status) => status === 201 });

export const updateGroup = (id, payload) =>
  http.post(`/group/edit/${id}`, payload, { validateStatus: (status) => status === 200 });

export const deleteGroup = (id, payload) =>
  http.post(`/group/delete/${id}`, payload, { validateStatus: (status) => status === 200 });

export const setGroupMembers = (id, payload) =>
  http.post(`/group/members/${id}`, payload, { validateStatus: (status) => status === 200 });

export const setGroupLinks = (id, payload) =>
  http.post(`/group/link/${id}`, payload, { validateStatus: (status) => status === 200 });

export const getUserLinks = (id) => http.get(`/user/link/${id}`, { validateStatus: (status) => status === 200 });

export const setUserLinks = (id, payload) =>
  http.post(`/user/link/${id}`, payload, { validateStatus: (status) => status === 200 });

export const getAllUsersLinks = () => http.get('/user/link', { validateStatus: (status) => status === 200 });

export const mqReq = (abortController, lastModified, etag) =>
  axios.get('/mq/req', {
    signal: abortController.signal,
    headers: {
      'Cache-Control': 'no-store',
      'If-Modified-Since': lastModified,
      'If-None-Match': etag,
    },
  });

// Rejection list

export const getRejectionList = () => http.get('/email/reject-list', { validateStatus: (status) => status === 200 });

export const removeFromRejectionList = (id) =>
  http.post(`/email/reject-list/remove/${id}`, { validateStatus: (status) => status === 200 });

export const addToRejectionList = (payload) =>
  http.post('/email/reject-list/add', payload, { validateStatus: (status) => status === 200 });

export const getRejectionListNotifyEmails = () =>
  http.get('/email/reject-list/notify', { validateStatus: (status) => status === 200 });

export const setRejectionListNotifyEmails = (payload) =>
  http.post('/email/reject-list/notify', payload, { validateStatus: (status) => status === 200 });

// Contacts

export const getUsersContactList = () =>
  http.get('/contact/manage/list', { validateStatus: (status) => status === 200 });

export const createAdminContact = (payload) =>
  http.post('/contact/manage/create', payload, { validateStatus: (status) => status === 201 });

export const editAdminContact = (id, payload) =>
  http.post(`/contact/manage/edit/${id}`, payload, { validateStatus: (status) => status === 200 });

export const deleteAdminContact = (ids) =>
  http.post('/contact/manage/delete', { ids }, { validateStatus: (status) => status === 200 });
